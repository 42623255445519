import { createApp } from 'vue';
import LoginApp from '@/components/login/LoginApp.vue';

//引入bootstrap.min.css
import 'bootstrap/dist/css/bootstrap.min.css';

//引入全局通用css
import '@/assets/css/common.css';

//引入路由
import router from '@/router/router.js';

const app = createApp(LoginApp);
app.use(router);
app.mount('#app');
